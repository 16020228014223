<template>
  <div class="publicParamsPage">
    <Listcontainer
      :searchText.sync="searchText"
      :placeholder="`参数名称/参数代码`"
      :multipleSelection="multipleSelection"
      :pagination="pagination"
      @add="add"
      @multipleDel="multipleDel"
      :isAdd="true"
      :addText="`添加参数`"
      @fetch="loadData"
    >
      <template v-slot:grid><Grid :Gridtype="1" :GridData="data" :isAdd="true" :isExport="true" :isUpdate="true" :isDel="true" @add="add" @deleteRow="deleteRow" @editRow="edit" /></template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
      </template>
    </Listcontainer>
    <Drawer
      :drawer.sync="drawer"
      @closeDrawer="closeDrawer"
      :title="'引用列表'"
      :AlertTitle="'该参数被以下设备模板和嵌套组引用'"
      :TemplateData="TemplateData.length ? TemplateData : null"
      :NestGroupData="NestGroupData.length ? NestGroupData : null"
      :isdel="true"
      @clickbtn="confirmDel()"
    />
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
import { TABLE } from '/app.config';
import Drawer from '@/components/drawer/index.vue';
const { del, edit } = TABLE.icons;
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid,
    Drawer
  },
  data() {
    return {
      searchText: '',
      multipleSelection: [],
      TemplateData: [],
      NestGroupData: [],
      data: [],
      columns: [
        {
          prop: 'name',
          label: '参数名称'
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'valueType',
          label: '数据类型'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'isCiteC',
          label: '是否被引用',
          align: 'center'
        },
        {
          prop: 'isAlarmC',
          label: '报警属性',
          align: 'center'
        }
      ],
      pagination: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      operations: {
        width: 200,
        handles: [
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.deleteRow
          }
        ]
      },
      drawer: false,
      paramId: '',
      paramIds: [],
      isdels: false
    };
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    add() {
      localStorage.setItem('isGroup', 'params');
      this.$router.push({ path: '/publicparams/edit', query: { data: null } });
    },
    edit(item) {
      localStorage.setItem('isGroup', 'params');
      this.$router.push({ path: '/publicparams/edit', query: { data: { ...item, isGroup: false } } });
    },
    async loadData(param = {}) {
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupId: 0
      };
      const res = await this.$apis.publicParams.list(data);
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
      res.items.forEach(item => {
        if (item.isCite) {
          item.isCiteC = '是';
        } else {
          item.isCiteC = '否';
        }
        if (item.isAlarm) {
          item.isAlarmC = '是';
        } else {
          item.isAlarmC = '否';
        }
      });
      this.data = res.items;
    },
    async multipleDel() {
      this.isdels = true;
      this.paramIds = [];
      this.multipleSelection.forEach(item => this.paramIds.push(item.paramId));
      const { deviceParamGroupVoList, deviceTemplateVoList } = await this.$apis.publicParams.previewCite({ paramIds: this.paramIds.join(','), type: 1 });
      if (deviceParamGroupVoList.length || deviceTemplateVoList.length) {
        this.NestGroupData = deviceParamGroupVoList;
        this.TemplateData = deviceTemplateVoList;
        this.drawer = true;
      } else {
        this.$confirm('确认要删除已选参数吗?', '删除参数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { fail } = await this.$apis.publicParams.del({ paramIds: this.paramIds.join(','), type: 1 });
          this.loadData();
          if (fail) {
            return false;
          }
        });
      }
    },
    async confirmDel() {
      const { fail } = await this.$apis.publicParams.del({ paramIds: this.isdels ? this.paramIds.join(',') : this.paramId, type: 1 });
      this.drawer = false;
      this.loadData();
      if (fail) {
        return false;
      }
    },
    async deleteRow(row) {
      this.isdels = false;
      this.paramId = row.paramId;
      const { deviceParamGroupVoList, deviceTemplateVoList } = await this.$apis.publicParams.previewCite({ paramIds: row.paramId, type: 1 });
      if (deviceParamGroupVoList.length || deviceTemplateVoList.length) {
        this.NestGroupData = deviceParamGroupVoList;
        this.TemplateData = deviceTemplateVoList;
        this.drawer = true;
      } else {
        this.$confirm('确认要删除已选参数吗?', '删除参数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(async () => {
          const { fail } = await this.$apis.publicParams.del({ paramIds: row.paramId, type: 1 });
          this.loadData();
          if (fail) {
            return false;
          }
        });
      }
    },
    closeDrawer() {
      this.drawer = false;
    }
  }
};
</script>

<style scoped>
tr th {
  background-color: #f3f3f3 !important;
}
.el-table thead {
  color: #585858 !important;
  font-size: 14px;
}
.el-icon-menu {
  margin-left: 20px;
  cursor: pointer;
}
.publicParamsPage {
  height: 100%;
}
</style>
